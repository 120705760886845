import {
    ReactiveList,
    ResultList
} from '@appbaseio/reactivesearch';
import '../Groups/CSS/DataOverviewList.css'

const { ResultListWrapper } = ReactiveList;

const DataOverviewDetails = ({ interactiveIds }) => {
    return (
        <div>
            <ReactiveList
                react={interactiveIds}
                componentId="SearchResult"
                dataField="LOT_number"
                size={100}
                sortOptions={[
                    {
                        dataField: 'timestamp',
                        sortBy: 'desc',
                    },
                ]}
            >
                {({ data }) => (
                    <ResultListWrapper >
                        {data.map(item => {
                            const timestampWithoutSpace = item.timestamp.trim();
                            const date = new Date(parseInt(timestampWithoutSpace));
                            const formattedDate = new Intl.DateTimeFormat('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                timeZone: 'UTC',
                            }).format(date);

                            const itemTextWithDate = `${item.mid} | ${formattedDate}`;

                            return (
                                <ResultListWrapper key={item._id} >
                                    <ResultList >
                                        <div className="result-list-container">
                                            <h4 className="result-list-title">{itemTextWithDate}</h4>

                                            <table className="result-list-description">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <b>Basic Type:</b> {item.basic_type} | <b>Carrier:</b> {item.carrier} | <b>EPA:</b> {item.epa} | <b>Chamber:</b> {item.chamber} | <b>Device:</b> {item.device} | <b>Equipment:</b> {item.equipment}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Job ID:</b> {item.jobid} | <b>MES Entity:</b> {item.mes_entity} | <b>Meta Equipment:</b> {item.metaequipment} | <b>Meta Site:</b> {item.metasite} | <b>Meta Workcenter:</b> {item.metaworkcenter} | <b>Port:</b> {item.port}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>PPID:</b> {item.ppid} | <b>Process Class:</b> {item.process_class} | <b>Process Group:</b> {item.process_group} | <b>Product:</b> {item.product} | <b>Product Type:</b> {item.product_type} | <b>Route:</b> {item.route}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Slot:</b> {item.slot} | <b>Workcenter:</b> {item.workcenter} | <b>Seq:</b> {item.seq} | <b>wafer_id:</b> {item.wafer_id} | <b>wafer_pos:</b> {item.wafer_pos} | <b>total_number_of_wafers:</b> {item.total_number_of_wafers}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ResultList>
                                </ResultListWrapper>
                            );
                        })}
                    </ResultListWrapper>
                )}
            </ReactiveList>;
        </div>
    )
}

export default DataOverviewDetails;