import {
    ReactiveChart
} from '@appbaseio/reactivesearch';
import { useState, useEffect } from 'react';
import getRandomColors from './ChartOptions/GetRandomColors';

const colors = getRandomColors(30);


const maxAggQuery = {
    reconstruction_loss: {
        max: {
            field: 'reconstruction_loss'
        }
    }
}

const minAggQuery = {
    reconstruction_loss: {
        min: {
            field: 'reconstruction_loss'
        }
    }
}

const avgAggQuery = {
    reconstruction_loss: {
        avg: {
            field: 'reconstruction_loss'
        }
    }
}

const getInterval = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const interval = urlParams.getAll('interval');
    if (interval.length > 0) {
        return interval[0];
    } else {
        return '1M'
    }
}


const getAgg = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const selectedAgg = urlParams.getAll('agg');
    if (selectedAgg.length > 0) {
        return selectedAgg[0];
    } else {
        return 'avg'
    }
}

const ReactiveLineChartRelative = ({ componentId, isCountChart, interactiveIds }) => {
    const selectedAgg = getAgg();
    let aggQuery = avgAggQuery;
    switch (selectedAgg) {
        case 'min':
            aggQuery = minAggQuery;
            break;
        case 'max':
            aggQuery = maxAggQuery;
            break;
        case 'avg':
            aggQuery = avgAggQuery;
            break;
        default:
            aggQuery = avgAggQuery;
            break;
    }
    return (
        <ReactiveChart
            className="custom-chart"
            style={{ height: '1000%', width: '100%', backgroundColor: '#C5DAEE' }}
            componentId={componentId}
            title="Reactive Chart"
            dataField="relative_timestamp"
            chartType="line"
            URLParams
            showFilter
            useAsFilter={false}
            react={interactiveIds}
            defaultQuery={() => ({
                query: {
                    range: {
                        relative_timestamp: {
                            lte: 'now-52y/y',
                            gte: 'now-54y/y'
                        },
                    },
                },
                aggs: {
                    timestampAggs: {
                        date_histogram: {
                            field: 'relative_timestamp',
                            calendar_interval: getInterval(),
                            format: 'yyyy-MM-dd hh:mm',
                        },
                        aggs: {
                            lotNumbersAggs: {
                                terms: {
                                    field: 'LOT_number',
                                    size: 30
                                },
                                aggs: aggQuery
                            },
                        },
                    },
                },
            })}
            setOption={({ rawData }) => {
                if (!rawData || !rawData.aggregations) {
                    return {}; // Handle no data scenario
                }

                const aggs = rawData.aggregations;
                const timestampLotNumberTable = {};
                const lotNumberTimestampTable = {};
                // Process aggregation results
                aggs.timestampAggs.buckets.forEach((timestampBucket) => {
                    const timestamp_dt = timestampBucket.key_as_string;
                    const lotNumbersAggs = timestampBucket.lotNumbersAggs.buckets;

                    const lotNumberData = {};

                    lotNumbersAggs.forEach((lotNumberBucket) => {
                        const lotNumber = lotNumberBucket.key;
                        if (isCountChart) {
                            lotNumberData[lotNumber] = lotNumberBucket.doc_count;
                        } else {
                            lotNumberData[lotNumber] = lotNumberBucket.reconstruction_loss.value.toFixed(2);
                        }
                    });

                    timestampLotNumberTable[timestamp_dt] = lotNumberData;

                    lotNumbersAggs.forEach((lotNumberBucket) => {
                        const lotNumber = lotNumberBucket.key;
                        if (!lotNumberTimestampTable[lotNumber]) {
                            lotNumberTimestampTable[lotNumber] = {};
                        }
                        lotNumberTimestampTable[lotNumber][timestamp_dt] = lotNumberData[lotNumber];
                    });
                });
                return {
                    legend: {},
                    xAxis: {
                        data: Object.keys(timestampLotNumberTable).sort(),
                    },
                    yAxis: {},
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'line',
                            axis: 'x',
                        },
                        formatter: (params) => {
                            let tooltip = '<b>' + params[0].name + '</b><br>';

                            params.forEach((param) => {
                                if (param.value) { // Only include non-zero values in the tooltip
                                    const dot = `<span style="display:inline-block; width:10px; height:10px; border-radius:50%; background-color:${param.color}; margin-right:6px;"></span>`;
                                    const formattedValue = param.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                                    tooltip += `${dot}<b>${param.seriesName}:</b> ${formattedValue}<br>`;
                                }
                            });

                            return tooltip;
                        },
                    },
                    series: Object.keys(lotNumberTimestampTable).map((lot_number, index) => ({
                        data: Object.keys(timestampLotNumberTable)
                            .sort()
                            .map(timestamp_dt => lotNumberTimestampTable[lot_number][timestamp_dt]), // Handle missing data,
                        connectNulls: true,
                        type: 'line',
                        name: lot_number,
                        itemStyle: {
                            color: colors[index % colors.length],
                        },
                    })),
                };
            }}
        />
    )
}

const LSTMOverviewRelative = ({ interactiveIds }) => {

    const [selectedInterval, setSelectedInterval] = useState('1h');
    const [selectedAgg, setSelectedAgg] = useState('avg');

    const handleIntervalChange = (event) => {
        const newInterval = event.target.value;
        setSelectedInterval(newInterval);

        // Update URL with new interval value
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('interval', newInterval);
        window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);

        // Reload the window
        window.location.reload();
    };
    const handleAggChange = (event) => {
        const newAgg = event.target.value;
        setSelectedAgg(newAgg);

        // Update URL with new interval value
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('agg', newAgg);
        window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);

        // Reload the window
        window.location.reload();
    };

    useEffect(() => {
        // Get the interval value from URL parameters
        const searchParams = new URLSearchParams(window.location.search);
        const intervalParam = searchParams.get('interval');
        const aggParam = searchParams.get('agg');

        if (intervalParam) {
            setSelectedInterval(intervalParam);
        }
        if (aggParam) {
            setSelectedAgg(aggParam);
        }
    }, []);

    return (
        <div className="row">
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <b><label htmlFor="intervalSelect">Aggregation: </label></b>
                <select id="aggSelect" value={selectedAgg} onChange={handleAggChange}>
                    <option value="max">MAX</option>
                    <option value="min">MIN</option>
                    <option value="avg">AVG</option>
                </select>
                <b><label htmlFor="intervalSelect">Interval: </label></b>
                <select id="intervalSelect" value={selectedInterval} onChange={handleIntervalChange}>
                    <option value="1M">Month</option>
                    <option value="1w">Week</option>
                    <option value="1d">Day</option>
                    <option value="1h">Hour</option>
                </select>
            </div>
            <div style={{ minHeight: '400px' }}>
                <h5 style={{ textAlign: 'center' }}>Reconstruction Loss per Lot</h5>
                <ReactiveLineChartRelative
                    componentId="relativeLineChartReconLoss"
                    isCountChart={false}
                    interactiveIds={interactiveIds}
                />
            </div>
            <div>
                <h5 style={{ textAlign: 'center' }}>No of Doucments per Lot</h5>
                <ReactiveLineChartRelative
                    componentId="relativeLineChartCount"
                    isCountChart={true}
                    interactiveIds={interactiveIds}
                />
            </div>
        </div>
    )
}

export default LSTMOverviewRelative;