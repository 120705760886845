import { useReactiveProps } from '../../ReactiveProps';
import {
    ReactiveBase,
    ReactiveComponent,
    MultiList,
    SelectedFilters
} from '@appbaseio/reactivesearch';
import { get, map } from 'lodash';
import theme from '../../pages/ReactiveBaseTheme'
import ReactApexChart from 'react-apexcharts';
import ErrorBoundary from '../ErrorBoundary';
import { Spinner } from 'react-bootstrap';
import './CSS/TimeSeries.css'

const interactiveIds = {
    and: [
        'Lot_Number',
        'Lot_Type',
        'Feature',
        'Start_Time_Range'
    ],
};

const getBarChartOptions = (dataKeys) => {
    const BarChartOptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        colors: ['#1f9c40', '#cf6c25', '#a80a17'],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
                borderRadius: 4
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: dataKeys,
            title: {
                text: 'Lot Type'
            }
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        yaxis: {
            tickAmount: 11,
            min: 0,
            max: 1,
            labels: {
                formatter: function (value) {
                    return parseFloat(value).toFixed(1); // Format the label to display one decimal place
                }
            },
            title: {
                text: 'F1 Score'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center'
        }
    }
    return BarChartOptions;
}

const getLineChartOptions = (dataKeys, termField) => {
    const LineChartOptions = {
        chart: {
            height: 350,
            type: 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
            },
            toolbar: {
                show: true
            }
        },
        colors: ['#1f9c40', '#cf6c25', '#a80a17'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        markers: {
            size: 0.5
        },
        xaxis: {
            categories: dataKeys,
            title: {
                text: termField
            }, labels: {
                show: true,
            },
            show: false
        },
        yaxis: {
            tickAmount: 11,
            min: 0,
            max: 1,
            labels: {
                formatter: function (value) {
                    return parseFloat(value).toFixed(1);
                }
            },
            title: {
                text: 'Prediction Probability'
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center'
        }
    }
    return LineChartOptions;
}

const getPieChartOptions = (dataKeys) => {
    const PieChartOptions = {
        chart: {
            width: 450,
            type: 'pie',
        },
        labels: dataKeys,
        legend: {
            position: 'top',
            horizontalAlign: 'center',
        },
        colors: ['#1f9c40', '#a80a17'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                },
            },
        }],
    }
    return PieChartOptions;
}

const LineChart = ({ componentId, termField, size }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: {
                    data: {
                        terms: {
                            field: termField,
                            size: size,
                            order: {
                                minProbability: 'asc'
                            }
                        },
                        aggs: {
                            maxProbability: {
                                max: {
                                    field: 'probability'
                                },
                            },
                            minProbability: {
                                min: {
                                    field: 'probability'
                                },
                            },
                            avgProbability: {
                                avg: {
                                    field: 'probability'
                                },
                            },
                        },
                    },
                },
                size: 0,
            })
            }
            URLParams
            showFilter
            react={interactiveIds}
            render={({ aggregations, ...rest }) => {
                let datakeys = [];
                let maxValues = [];
                let minValues = [];
                let avgValues = [];
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        datakeys.push(b.key);
                        maxValues.push(((b.maxProbability.value)).toFixed(1));
                        minValues.push(((b.minProbability.value)).toFixed(1));
                        avgValues.push(((b.avgProbability.value)).toFixed(1));
                    }
                );
                const dataSeries = [{ name: 'Max', data: maxValues }, { name: 'Avg', data: avgValues }, { name: 'Min', data: minValues }];

                const LineChartOptions = getLineChartOptions(datakeys, termField);
                return (
                    <div className='chart'>
                        <ReactApexChart options={LineChartOptions} series={dataSeries} type="line" height={450} />
                    </div>
                );
            }}
        />
    );
}

const BarChart = ({ componentId, interactiveIds, termField }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: {
                    data: {
                        terms: {
                            field: termField,
                            size: 3,
                            order: {
                                _key: 'desc'
                            }
                        },
                        aggs: {
                            maxProbability: {
                                max: {
                                    field: 'probability'
                                },
                            },
                            minProbability: {
                                min: {
                                    field: 'probability'
                                },
                            },
                            avgProbability: {
                                avg: {
                                    field: 'probability'
                                },
                            },
                        },
                    },
                },
                size: 0,
            })
            }
            URLParams
            showFilter
            react={interactiveIds}
            render={({ aggregations, ...rest }) => {
                let datakeys = [];
                let maxValues = [];
                let minValues = [];
                let avgValues = [];
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        const capitalizedKey = b.key.charAt(0).toUpperCase() + b.key.slice(1);
                        datakeys.push(capitalizedKey);
                        maxValues.push(b.maxProbability.value.toFixed(2));
                        minValues.push(b.minProbability.value.toFixed(2));
                        avgValues.push(b.avgProbability.value.toFixed(2));
                    }
                );

                const dataSeries = [{ name: 'Max', data: maxValues }, { name: 'Avg', data: avgValues }, { name: 'Min', data: minValues }];

                const BarChartOptions = getBarChartOptions(datakeys);
                return (
                    <div className='chart'>
                        <ReactApexChart options={BarChartOptions} series={dataSeries} type="bar" height={450} />
                    </div>
                );
            }}
        />
    );
}

const PieChart = ({ componentId, interactiveIds, termField }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: {
                    data: {
                        terms: {
                            field: 'isMatch',
                            size: 2,
                            order: {
                                _key: 'desc'
                            }
                        },
                    },
                },
                size: 0,
            })
            }
            URLParams
            showFilter
            react={interactiveIds}
            render={({ aggregations, ...rest }) => {
                let datakeys = [];
                let values = [];
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        const capitalizedKey = b.key.charAt(0).toUpperCase() + b.key.slice(1);
                        datakeys.push(capitalizedKey);
                        values.push(b.doc_count);
                    }
                );
                const PieChartOptions = getPieChartOptions(datakeys);
                return (
                    <div className='chart'>
                        <ReactApexChart options={PieChartOptions} series={values} type="pie" height={450} />
                    </div>
                );
            }}
        />
    );
}

const TimeSeriesPrediction = ({ interactiveIds }) => {
    const reactiveProps = useReactiveProps('irel40_raw_kulim_timeanalysis_model_predictions');
    if (!reactiveProps) return <div />;
    return (
        <div>
            <ReactiveBase {...reactiveProps} theme={theme}>
                <div className="row g-2">
                    <div className="col-2">
                        <div className="p-1 bg-white">
                            <ErrorBoundary>
                                <MultiList
                                    componentId="Lot_Number"
                                    dataField="LOT_number"
                                    title="Lot Number"
                                    showCount
                                    showFilter
                                    showSearch={true}
                                    placeholder="Search Lot Number..."
                                    loader={<Spinner animation="border" size="sm" />}
                                    URLParams
                                    innerClass={{
                                        title: 'fw-bold',
                                        list: 'custom-multilist'
                                    }}
                                    className="mt-1"
                                    filterLabel="Lot Number"
                                />
                                <MultiList
                                    componentId="Lot_Type"
                                    dataField="LOT_type"
                                    title="Lot Types"
                                    showCount
                                    showFilter
                                    showSearch={false}
                                    loader={<Spinner animation="border" size="sm" />}
                                    URLParams
                                    innerClass={{
                                        title: 'fw-bold',
                                    }}
                                    className="mt-1"
                                    filterLabel="Lot Type"
                                />
                                <MultiList
                                    componentId="Feature"
                                    dataField="feature"
                                    title="Feature"
                                    showCount
                                    showFilter
                                    showSearch={false}
                                    loader={<Spinner animation="border" size="sm" />}
                                    URLParams
                                    innerClass={{
                                        title: 'fw-bold',
                                        list: 'custom-multilist'
                                    }}
                                    className="mt-1"
                                />
                            </ErrorBoundary>
                        </div>
                    </div>
                    <div className='col-10'>
                        <SelectedFilters className="mb-2" />

                        <div className="row">
                            <h5 style={{ textAlign: 'center' }}>Prediction Probability per Lot</h5>
                            <div className='col-12'>
                                <LineChart componentId='LotNoProbabilityLineChart' interactiveIds={interactiveIds} termField='LOT_number' size={50} />
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className='col-6'>
                                <h5 style={{ textAlign: 'center' }}>Lot Type wise Prediction Probability</h5>
                                <BarChart componentId='LotTypeProbabilityLineChart' interactiveIds={interactiveIds} termField='LOT_type' />
                            </div>
                            <div className='col-6'>
                                <h5 style={{ textAlign: 'center' }}>Prediction Match Rate</h5>
                                <PieChart componentId='MatchPieChart' interactiveIds={interactiveIds} termField='Match' />
                            </div>

                        </div>
                        <hr />
                        <div className="row">
                            <div className='col-12'>
                                <h5 style={{ textAlign: 'center' }}>Feature Value wise Prediction Probability</h5>
                                <LineChart componentId='FeatureProbabilityLineChart' interactiveIds={interactiveIds} termField='feature_value' size={75} />
                            </div>
                        </div>
                    </div>
                </div>
            </ReactiveBase>
        </div>
    )
}

export default TimeSeriesPrediction;