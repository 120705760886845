import { useReactiveProps } from '../../ReactiveProps';
import {
    MultiList,
    ReactiveComponent,
    SelectedFilters,
    DateRange
} from '@appbaseio/reactivesearch';
import { get, map } from 'lodash';
import ErrorBoundary from '../ErrorBoundary';
import ReactApexChart from 'react-apexcharts';
import getTreeMapOptions from './ChartOptions/TreeOptions';
import { Spinner } from 'react-bootstrap';
import './CSS/TimeSeries.css'

const interactiveIds = {
    and: [
        'Lot_Number',
        'Lot_Type',
        'Feature',
        'Start_Time_Range'
    ],
};

const getTreeData = (data) => {
    /*Data Format--->
      data= [
        x:lot_number,
        y:avg_duration
      }]
    */
    let x = data.key;
    let y = (data.duration.value / 86400000.0).toFixed(1); //converting ms to days.
    return { x, y }
}

const getBarChartOptions = (dataKeys, colors) => {
    const BarChartOptions = {
        chart: {
            height: 1000,
            type: 'bar',
        },
        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: '40%',
                distributed: true,
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: true
        },
        legend: {
            show: false
        },
        yaxis: {
            title: {
                text: 'Lot Number'
            }
        },
        xaxis: {
            categories: dataKeys,
            position: 'top'
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        tooltip: {
            enabled: true,
            shared: false,
            x: {
                formatter: function (value) {
                    return 'Lot Number: ' + value;
                },
            },
            y: {
                formatter: function (value) {
                    return 'Duration: ' + value + ' Days';
                },
                title: {
                    formatter: function () {
                        return '';
                    },
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
            },
        }
    }
    return BarChartOptions;
};

const getSamplesBarChartOptions = (dataKeys) => {
    const BarChartOptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        colors: ['#1f9c40', '#a80a17'],
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: dataKeys,
            position: 'top'
        },
        tooltip: {
            enabled: true,
            shared: false,
            x: {
                formatter: function (value) {
                    return 'Feature Value: ' + value;
                },
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center',
        },
    }

    return BarChartOptions;
};

const getLineChartOptions = (dataKeys) => {
    const LineChartOptions = {
        chart: {
            height: 350,
            type: 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
            },
            toolbar: {
                show: true
            }
        },
        colors: ['#1f9c40', '#a80a17'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        markers: {
            size: 0.5
        },
        xaxis: {
            categories: dataKeys,
            title: {
                text: 'Feature Values'
            }, labels: {
                show: false,
            },
            show: false
        },
        yaxis: {
            tickAmount: 10,
            min: 0,
            labels: {
                formatter: function (value) {
                    return parseFloat(value).toFixed(0);
                }
            },
            title: {
                text: 'Duration (Days)'
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center'
        }
    }
    return LineChartOptions;
}


const TreeMap = ({ componentId, lot_type }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    match: {
                        LOT_type: {
                            query: lot_type,
                        },
                    },
                },
                aggs: {
                    data: {
                        terms: {
                            field: 'LOT_number',
                            size: 100,
                        },
                        aggs: {
                            duration: {
                                avg: {
                                    field: 'duration(ms)'
                                },
                            },
                        },
                    },
                },
                size: 0,
            })
            }
            URLParams
            showFilter
            react={interactiveIds}
            render={({ aggregations, ...rest }) => {

                const data = map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        return getTreeData(b);
                    }
                );
                data.sort((a, b) => b.y - a.y);
                const dataCount = data.length;
                const treeMapOptions = getTreeMapOptions(lot_type, dataCount);
                return (
                    <div className='chart'>
                        <ReactApexChart options={treeMapOptions} series={[{ data: [...data] }]} type="treemap" height={350} />
                    </div>
                );
            }}
        />
    );
};

const LineChart = ({ componentId }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: {
                    data: {
                        terms: {
                            field: 'feature_value',
                            size: 100,
                            order: {
                                _key: 'asc'
                            }
                        },
                        aggs: {
                            lot_type: {
                                terms: {
                                    field: 'LOT_type',
                                    order: {
                                        duration: "desc"
                                    }
                                },
                                aggs: {
                                    duration: {
                                        avg: {
                                            field: 'duration(ms)'
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                size: 0,
            })
            }
            URLParams
            showFilter
            react={interactiveIds}
            render={({ aggregations, ...rest }) => {
                let datakeys = [];
                let passValues = [];
                let failValues = [];
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        datakeys.push(b.key);
                        if (b.lot_type.buckets.length === 2) {
                            passValues.push(((b.lot_type.buckets[0].duration.value) / (1000 * 60 * 60 * 24)).toFixed(1));
                            failValues.push(((b.lot_type.buckets[1].duration.value) / (1000 * 60 * 60 * 24)).toFixed(1));
                        } else {
                            if (b.lot_type.buckets[0].key === 'pass') {
                                passValues.push(((b.lot_type.buckets[0].duration.value) / (1000 * 60 * 60 * 24)).toFixed(1));
                                failValues.push(0);
                            } else {
                                passValues.push(0);
                                failValues.push(((b.lot_type.buckets[0].duration.value) / (1000 * 60 * 60 * 24)).toFixed(1));
                            }
                        }

                    }
                );
                let indices = datakeys.map((_, index) => index);
                // Sort passValues in descending order and rearrange the indices array accordingly
                indices.sort((a, b) => passValues[b] - passValues[a]);
                // Rearrange the elements in datakeys, passValues, and failValues based on the sorted indices
                datakeys = indices.map(index => datakeys[index]);
                passValues = indices.map(index => passValues[index]);
                failValues = indices.map(index => failValues[index]);

                const dataSeries = [{ name: 'Pass', data: passValues }, { name: 'Fail', data: failValues }];

                const LineChartOptions = getLineChartOptions(datakeys);
                return (
                    <div className='chart'>
                        <ReactApexChart options={LineChartOptions} series={dataSeries} type="line" height={450} />
                    </div>
                );
            }}
        />
    );
}

const DurationBarChart = ({ componentId }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: {
                    data: {
                        terms: {
                            field: 'LOT_number',
                            size: 100
                        },
                        aggs: {
                            lot_type: {
                                terms: {
                                    field: 'LOT_type'
                                },
                                aggs: {
                                    duration: {
                                        avg: {
                                            field: 'duration(ms)'
                                        },
                                    }
                                },
                            },
                        },
                    },
                },
                size: 0,
            })
            }
            URLParams
            showFilter
            react={interactiveIds}
            render={({ aggregations, ...rest }) => {
                let datakeys = [];
                let values = [];
                let colors = [];
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        datakeys.push(b.key);
                        if (b.lot_type.buckets[0].key === 'pass') {
                            values.push(((b.lot_type.buckets[0].duration.value) / (1000 * 60 * 60 * 24)).toFixed(1));
                            colors.push('#1f9c40');
                        } else {
                            values.push(((b.lot_type.buckets[0].duration.value) / (1000 * 60 * 60 * 24)).toFixed(1));
                            colors.push('#a80a17');
                        }
                        //
                    }
                );
                let indices = datakeys.map((_, index) => index);
                // Sort values in descending order and rearrange the indices array accordingly
                indices.sort((a, b) => values[b] - values[a]);
                // Rearrange the elements in datakeys, values, and colors based on the sorted indices
                datakeys = indices.map(index => datakeys[index]);
                values = indices.map(index => values[index]);
                colors = indices.map(index => colors[index]);

                const dataSeries = [{ data: values }];
                const BarChartOptions = getBarChartOptions(datakeys, colors);
                return (
                    <div className='chart'>
                        <ReactApexChart options={BarChartOptions} series={dataSeries} type="bar" height={1000} />
                    </div>
                );
            }}
        />
    );
}

const NoSamplesBarChart = ({ componentId }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: {
                    data: {
                        terms: {
                            field: 'feature_value',
                            size: 50,
                            order: {
                                _key: 'asc'
                            }
                        },
                        aggs: {
                            lot_type: {
                                terms: {
                                    field: 'LOT_type'
                                },
                                aggs: {
                                    no_samples: {
                                        avg: {
                                            field: 'num_samples'
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                size: 0,
            })
            }
            URLParams
            showFilter
            react={interactiveIds}
            render={({ aggregations, ...rest }) => {
                let datakeys = [];
                let passValues = [];
                let failValues = [];
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        datakeys.push(b.key);
                        if (b.lot_type.buckets.length === 2) {
                            passValues.push(((b.lot_type.buckets[0].no_samples.value)).toFixed(0));
                            failValues.push(((b.lot_type.buckets[1].no_samples.value)).toFixed(0));
                        } else {
                            if (b.lot_type.buckets[0].key === 'pass') {
                                passValues.push(((b.lot_type.buckets[0].no_samples.value)).toFixed(0));
                                failValues.push(0);
                            } else {
                                passValues.push(0);
                                failValues.push(((b.lot_type.buckets[0].no_samples.value)).toFixed(0));
                            }
                        }

                    }
                );
                let indices = datakeys.map((_, index) => index);

                // Sort passValues in descending order and rearrange the indices array accordingly
                indices.sort((a, b) => passValues[b] - passValues[a]);
                // Rearrange the elements in datakeys, passValues, and failValues based on the sorted indices
                datakeys = indices.map(index => datakeys[index]);
                passValues = indices.map(index => passValues[index]);
                failValues = indices.map(index => failValues[index]);

                const dataSeries = [{ name: 'Pass', data: passValues }, { name: 'Fail', data: failValues }];

                const barChartOptions = getSamplesBarChartOptions(datakeys);
                return (
                    <div className='chart'>
                        <ReactApexChart options={barChartOptions} series={dataSeries} type="bar" height={1000} />
                    </div>
                );
            }}
        />
    );
}

const TimeSeriesOverview = () => {
    const reactiveProps = useReactiveProps('irel40_raw_kulim_timeanalysis2');
    if (!reactiveProps) return <div />;

    return (
        <div className="row g-2">
            <div className="col-2">
                <div className="p-1 bg-white">
                    <ErrorBoundary>
                        <h6>Select Event Time (yyyy-mm-dd)</h6>
                        <DateRange
                            componentId="Start_Time_Range"
                            URLParams
                            showFilter
                            dataField="timestamp"
                            filterLabel="Start Time Range"
                        />
                        <hr />
                        <MultiList
                            componentId="Lot_Number"
                            dataField="LOT_number"
                            title="Lot Number"
                            showCount
                            showFilter
                            showSearch={true}
                            placeholder="Search Lot Number..."
                            loader={<Spinner animation="border" size="sm" />}
                            URLParams
                            innerClass={{
                                title: 'fw-bold',
                                list: 'custom-multilist'
                            }}
                            className="mt-1"
                            filterLabel="Lot Number"
                        />
                        <MultiList
                            componentId="Lot_Type"
                            dataField="LOT_type"
                            title="Lot Types"
                            showCount
                            showFilter
                            showSearch={false}
                            loader={<Spinner animation="border" size="sm" />}
                            URLParams
                            innerClass={{
                                title: 'fw-bold',
                            }}
                            className="mt-1"
                            filterLabel="Lot Type"
                        />
                        <MultiList
                            componentId="Feature"
                            dataField="feature"
                            title="Feature"
                            showCount
                            showFilter
                            showSearch={false}
                            loader={<Spinner animation="border" size="sm" />}
                            URLParams
                            innerClass={{
                                title: 'fw-bold',
                                list: 'custom-multilist'
                            }}
                            className="mt-1"
                        />
                        {/*<TreeList
                            componentId="treeListSensor"
                            dataField={['LOT_type']}
                            title="Products"
                        />*/}
                    </ErrorBoundary>
                </div>
            </div>
            <div className='col-10'>
                <SelectedFilters className="mb-2" />
                <div className='row g2'>
                    <div className='col-12'>
                        <h5 style={{ textAlign: 'center' }}>Duration over Feature Values</h5>
                        <ErrorBoundary>
                            <LineChart
                                componentId="chart_overview"
                            />
                        </ErrorBoundary>
                    </div>
                    <hr />
                </div>
                <div className='row g2'>
                    <div className='col-6'>
                        <ErrorBoundary>
                            <TreeMap componentId="treemap_pass" lot_type="pass" />
                        </ErrorBoundary>
                        <hr />
                    </div>
                    <div className='col-6'>
                        <ErrorBoundary>
                            <TreeMap componentId="treemap_fail" lot_type="fail" />
                        </ErrorBoundary>
                        <hr />
                    </div>
                </div>
                <div className='row g2'>
                    <div className='col-6'>
                        <ErrorBoundary>
                            <h5 style={{ textAlign: 'center' }}>Average duration per lot</h5>
                            <DurationBarChart componentId='avg_duration_lot' />
                        </ErrorBoundary>
                    </div>
                    <div className='col-6'>
                        <ErrorBoundary>
                            <h5 style={{ textAlign: 'center' }}>Average count of Samples per feature</h5>
                            <NoSamplesBarChart componentId='num_samples' />
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeSeriesOverview;