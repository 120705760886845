/* eslint-disable react/jsx-props-no-spreading */
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';
import Auth0LoginTrigger from './Auth0LoginTrigger';

const PrivateRoute = (props) => {
  const { component: Component, element, children, ...rest } = props;
  const { isAuthenticated, user } = useAuth0();

  if (!isAuthenticated) {
    return <Auth0LoginTrigger />;
  } else {
    const role = process.env.REACT_APP_AUTH0_USERROLE;
    const userProfile = user?.['https://carwatch.plexalytics.com/user'];
    const userRoles = [...(userProfile ? userProfile.roles : [])];

    if (userRoles && userRoles.includes(role)) {
      if (!Component && !element && !children) throw Error('nothing to render');

      if (Component) return <Component {...rest} />;
      if (element) {
        return element;
      }
      return children;
    } else return null;
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  element: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

PrivateRoute.defaultProps = {
  component: undefined,
  element: undefined,
  children: undefined,
};

export default PrivateRoute;
