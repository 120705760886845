
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from './components/PageLoader';
import { Container, Nav, Navbar, Row } from 'react-bootstrap';
import { LoginButton } from './components/Buttons/LoginButton';
import { LogoutButton } from './components/Buttons/LogoutButton';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import MLResults from './pages/MLResults';
import LSTM from './pages/LSTM';
import TimeSeries from './pages/TimeSeries';
import Overview from './pages/Overview';
import { useReactiveProps } from './ReactiveProps';

function App() {
  const reactiveProps = useReactiveProps('irel40_raw_kulim');
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) return <PageLoader />;

  return (
    <>
      <Container fluid className="border-bottom bg-white">
        <Row className="g-2">
          <Navbar className="col py-0">
            <Navbar.Brand href="https://www.irel40.eu/" target="_blank">
              <img
                src="irel-logo.png"
                className="d-inline-block align-top"
                alt="iRel Logo"
                height="50"
              />
            </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              {isAuthenticated && (
                <>
                  <Nav.Link href="/lstm_analysis?interval=1M&agg=max">LSTM Analysis</Nav.Link>
                  <Nav.Link href='/time_series_analysis?Feature=%5B"carrier"%5D'>Time Series Analysis</Nav.Link>
                  <Nav.Link href="/ml_results">ML Results</Nav.Link>
                </>
              )}
            </Nav>
            <Nav>
              {reactiveProps && <LogoutButton />}
              {!reactiveProps && <LoginButton />}
            </Nav>

          </Navbar>
        </Row>
      </Container>
      <Routes>
        <Route index element={<Overview />} />
        <Route
          path="/ml_results"
          element={<PrivateRoute element={<MLResults />} />}
        />
        <Route
          path="/lstm_analysis"
          element={<PrivateRoute element={<LSTM />} />}
        />
        <Route
          path="/time_series_analysis"
          element={<PrivateRoute element={<TimeSeries />} />}
        />
      </Routes>
    </>
  );
}

export default App;
