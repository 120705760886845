import React from 'react';
import {
  SelectedFilters,
  MultiList,
  ReactiveBase
} from '@appbaseio/reactivesearch';
import { useReactiveProps } from '../ReactiveProps';
import { Tabs, Tab, Spinner } from 'react-bootstrap';
import MLResultsSamplingOver3 from '../components/Groups/MLResultsSamplingOver3';
import MLResultsSamplingUnder3 from '../components/Groups/MLResultsSamplingUnder3';
import ErrorBoundary from '../components/ErrorBoundary';
import theme from './ReactiveBaseTheme';
import '../components/Groups/CSS/MLResults.css'

const interactiveIds = {
  and: [
    'Feature'
  ],
};

const getTimeSeriesLinkParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const features = urlParams.getAll('Feature');

  // Create the redirect link with the parameters
  const serverUrl = window.location.origin;
  // Create the redirect link with the parameters
  let redirectUrl = `${serverUrl}/time_series_analysis?`;

  if (features && features.length > 0) {
    redirectUrl += `Feature=${features.map(number => encodeURIComponent(number)).join(',')}&`;
  }
  // Redirect the user to the new location
  window.location.href = redirectUrl;
};

const handleGoBack = () => {
  window.history.back();
};

export default function MLResults(props) {
  const reactiveProps = useReactiveProps('irel40_raw_kulim_timeanalysis_model_results');
  if (!reactiveProps) return <div style={{ textAlign: "center" }}>Please Login</div>;

  return (
    <div>
      <ReactiveBase {...reactiveProps} theme={theme}>
        <div className="container-fluid my-2">
          <div className="row g-2">
            <div className="col-2">

              <div className="p-1 bg-white">
                <ErrorBoundary>
                  <MultiList
                    componentId="Feature"
                    dataField="training features"
                    title="Training Feature"
                    showCount
                    showFilter
                    showSearch={false}
                    loader={<Spinner animation="border" size="sm" />}
                    URLParams
                    innerClass={{
                      title: 'fw-bold',
                      list: 'custom-multilist'
                    }}
                    style={{ height: '800px' }}
                    className="mt-1"
                  />
                </ErrorBoundary>
              </div>
            </div>
            <div className="col-10">
              <div className="p-1 bg-white">
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Check filtered Data:</span>
                    <button
                      style={{
                        padding: '3px 5px',
                        borderRadius: '5px',
                        backgroundColor: '#07529a',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        marginBottom: '5px',
                        marginRight: '5px'
                      }}
                      onClick={getTimeSeriesLinkParams}
                    >
                      Time Series
                    </button>
                    <button
                      style={{
                        padding: '3px 15px',
                        borderRadius: '5px',
                        backgroundColor: '#38393b',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        marginBottom: '5px',
                        marginRight: '5px'
                      }}
                      onClick={handleGoBack}
                      title="Go Back"
                    >
                      <b>⭯</b>
                    </button>
                  </div>
                </div>
                <SelectedFilters className="mb-2" />
                <Tabs defaultActiveKey="samplingUpto3Tab" className="mb-2">
                  <Tab eventKey="samplingUpto3Tab" title="Group 1">
                    <MLResultsSamplingUnder3 interactiveIds={interactiveIds} />
                  </Tab>
                  <Tab eventKey="samplingAbove3Tab" title="Group 2">
                    <MLResultsSamplingOver3 interactiveIds={interactiveIds} />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </ReactiveBase>
    </div>
  )
}