const getBarChartOptions = (dataKeys) => {
    const BarChartOptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        colors: ['#1f9c40', '#a80a17'],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
                borderRadius: 4
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: dataKeys,
            title: {
                text: 'Training Feature'
            }
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        yaxis: {
            tickAmount: 11,
            min: 0,
            max: 1,
            labels: {
                formatter: function (value) {
                    return parseFloat(value).toFixed(1); // Format the label to display one decimal place
                }
            },
            title: {
                text: 'F1 Score'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center'
        }
    }
    return BarChartOptions;
};

export default getBarChartOptions;