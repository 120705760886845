import React from 'react';
import {
  MultiList,
  ReactiveBase,
  DynamicRangeSlider,
  ReactiveChart,
  DateRange
} from '@appbaseio/reactivesearch';
import { Tabs, Tab, Spinner } from 'react-bootstrap';
import { useReactiveProps } from '../ReactiveProps';
import theme from './ReactiveBaseTheme';
import ErrorBoundary from '../components/ErrorBoundary'
import SelectedFilters from '@appbaseio/reactivesearch/lib/components/basic/SelectedFilters';
import LSTMDetailsCard from '../components/Groups/LSTMDetailsCard.jsx';
import LSTMCharts from '../components/Groups/LSTMCharts';
import LSTMOverviewRelative from '../components/Groups/LSTMOverviewRelative';
import LSTMOverviewOriginal from '../components/Groups/LSTMOverviewOriginal';
import LSTMPredictions from '../components/Groups/LSTMPredictions';
import '../components/Groups/CSS/LSTM.css'
import getRandomColors from '../components/Groups/ChartOptions/GetRandomColors';


const interactiveIds = {
  and: [
    'Lot_Number',
    'Lot_Type',
    'Reconstruction_Loss',
    'Start_Time_Range'
  ],
};

const colors = getRandomColors(30);

const handleGoBack = () => {
  window.history.back();
};

const getOverviewLinkParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const lotNumbers = urlParams.getAll('Lot_Number');
  const startTimeRange = urlParams.get('Start_Time_Range') ? JSON.parse(urlParams.get('Start_Time_Range')) : null;

  // Create the redirect link with the parameters
  const serverUrl = window.location.origin;
  // Create the redirect link with the parameters
  let redirectUrl = `${serverUrl}/?`;

  if (lotNumbers && lotNumbers.length > 0) {
    redirectUrl += `Lot_Number=${lotNumbers.map(number => encodeURIComponent(number)).join(',')}&`;
  }
  if (startTimeRange !== null) {
    redirectUrl += `Start_Time_Range=${encodeURIComponent(JSON.stringify(startTimeRange))}&`;
  }
  // Remove the last '&' from the URL if it exists
  if (redirectUrl.endsWith('&')) {
    redirectUrl = redirectUrl.slice(0, -1);
  }
  // Redirect the user to the new location
  window.location.href = redirectUrl;
};

const getTimeSeriesLinkParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const lotNumbers = urlParams.getAll('Lot_Number');
  const startTimeRange = urlParams.get('Start_Time_Range') ? JSON.parse(urlParams.get('Start_Time_Range')) : null;
  const lotType = urlParams.getAll('Lot_Type');

  // Create the redirect link with the parameters
  const serverUrl = window.location.origin;
  // Create the redirect link with the parameters
  let redirectUrl = `${serverUrl}/time_series_analysis?`;

  if (lotNumbers && lotNumbers.length > 0) {
    redirectUrl += `Lot_Number=${lotNumbers.map(number => encodeURIComponent(number)).join(',')}&`;
  }
  if (startTimeRange !== null) {
    redirectUrl += `Start_Time_Range=${encodeURIComponent(JSON.stringify(startTimeRange))}&`;
  }
  if (lotType && lotType.length > 0) {
    redirectUrl += `Lot_Type=${lotType.map(type => encodeURIComponent(type)).join(',')}&`;
  }
  // Remove the last '&' from the URL if it exists
  if (redirectUrl.endsWith('&')) {
    redirectUrl = redirectUrl.slice(0, -1);
  }
  // Redirect the user to the new location
  window.location.href = redirectUrl;
};
export default function LSTM(props) {
  const reactiveProps = useReactiveProps('irel40_raw_kulim_results');
  if (!reactiveProps) return <div style={{ textAlign: "center" }}>Please Login</div>;

  return (
    <div>
      <hr />
      <ErrorBoundary>
        <ReactiveBase {...reactiveProps} theme={theme}>
          <div className="container-fluid my-2">
            <div className="row g-2">
              <div className="col-3">
                <div className="p-1 bg-white">
                  <h6>Select Event Time (yyyy-mm-dd)</h6>
                  <DateRange
                    componentId="Start_Time_Range"
                    URLParams
                    showFilter
                    dataField="timestamp"
                    filterLabel="Start Time Range"
                  />
                  <hr />
                  <MultiList
                    componentId="Lot_Number"
                    dataField="LOT_number"
                    title="Lot Number"
                    showCount
                    showFilter
                    showSearch={true}
                    placeholder="Search Lot Number..."
                    loader={<Spinner animation="border" size="sm" />}
                    URLParams
                    innerClass={{
                      title: 'fw-bold',
                    }}
                    className="mt-1"
                    filterLabel="Lot Number"
                  />
                  <MultiList
                    componentId="Lot_Type"
                    dataField="LOT_type"
                    title="Lot Type"
                    showCount
                    showFilter
                    showSearch={false}
                    loader={<Spinner animation="border" size="sm" />}
                    URLParams
                    innerClass={{
                      title: 'fw-bold',
                    }}
                    className="mt-1"
                    filterLabel="Lot Type"
                  />
                  <DynamicRangeSlider
                    componentId="Reconstruction_Loss"
                    dataField="reconstruction_loss"
                    title="Reconstruction Loss"
                    showFilter
                    loader={<Spinner animation="border" size="sm" />}
                    URLParams
                    innerClass={{
                      title: 'fw-bold',
                    }}
                    includeNullValues={false}
                    tooltipTrigger="hover"
                    showHistogram={true}
                    className="mt-1"
                    filterLabel="Reconstruction Loss"
                  />
                  <hr />
                </div>
              </div>
              <div className="col-9">
                <div className="p-1 bg-white">
                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Check filtered Data:</span>
                      <button
                        style={{
                          padding: '3px 5px',
                          borderRadius: '5px',
                          backgroundColor: '#07529a',
                          color: '#ffffff',
                          border: 'none',
                          cursor: 'pointer',
                          marginBottom: '5px',
                          marginRight: '5px'
                        }}
                        onClick={getOverviewLinkParams}
                      >
                        Raw Data
                      </button>
                      <button
                        style={{
                          padding: '3px 5px',
                          borderRadius: '5px',
                          backgroundColor: '#07529a',
                          color: '#ffffff',
                          border: 'none',
                          cursor: 'pointer',
                          marginBottom: '5px',
                          marginRight: '5px'
                        }}
                        onClick={getTimeSeriesLinkParams}
                      >
                        Time Series
                      </button>
                      <button
                        style={{
                          padding: '3px 15px',
                          borderRadius: '5px',
                          backgroundColor: '#38393b',
                          color: '#ffffff',
                          border: 'none',
                          cursor: 'pointer',
                          marginBottom: '5px',
                          marginRight: '5px'
                        }}
                        onClick={handleGoBack}
                        title="Go Back"
                      >
                        <b>⭯</b>
                      </button>
                    </div>
                  </div>
                  <SelectedFilters className="mb-2" />
                  <div
                    className="border shadow-sm p-1 mb-2"
                    style={{ minWidth: '300px' }}
                  >
                    <Tabs defaultActiveKey="chartRelativeTab" className="mb-2">
                      <Tab eventKey="chartRelativeTab" title="Overview (Relative Timestamps)">
                        <div className="row">
                          <LSTMOverviewRelative interactiveIds={interactiveIds} />
                        </div>
                      </Tab>
                      <Tab eventKey="chartOriginalTab" title="Overview (Original Timestamps)">
                        <div className="row">
                          <LSTMOverviewOriginal interactiveIds={interactiveIds} />
                        </div>
                      </Tab>
                      <Tab eventKey="chartsOthersTab" title="Charts">
                        <LSTMCharts interactiveIds={interactiveIds} />
                      </Tab>
                      <Tab eventKey="chartsPredictionsTab" title="Predictions">
                        <LSTMPredictions />
                      </Tab>
                      <Tab eventKey="detailsTab" title="Details">
                        <LSTMDetailsCard interactiveIds={interactiveIds} />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactiveBase>

      </ErrorBoundary>
      <hr />
    </div>
  )
}