import ErrorBoundary from "../ErrorBoundary";
import { ReactiveComponent } from "@appbaseio/reactivesearch";
import { get, map } from 'lodash';
import '../Groups/CSS/MLResultTables.css'

const FeatureTable = ({ componentId, interactiveIds }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    bool: {
                        must: [
                            {
                                range: {
                                    'length of input features': {
                                        gte: 4
                                    }
                                }
                            }
                        ],
                        must_not: [
                            {
                                terms: {
                                    sampling: ["over", "under"]
                                }
                            }
                        ]
                    }
                },
                aggs: {
                    data: {
                        terms: {
                            field: 'training features',
                            size: 1000
                        },
                        aggs: {
                            pass_test: {
                                avg: {
                                    field: 'Test F1 Score - PASS LOT'
                                },
                            },
                            fail_test: {
                                avg: {
                                    field: 'Test F1 Score - FAIL LOT'
                                }
                            },
                            pass_train: {
                                avg: {
                                    field: 'Train F1 Score - PASS LOT'
                                },
                            },
                            fail_train: {
                                avg: {
                                    field: 'Train F1 Score - FAIL LOT'
                                }
                            }
                        },
                    },
                },
                size: 0,
            })
            }
            URLParams
            showFilter
            react={interactiveIds}
            render={({ aggregations, ...rest }) => {
                let features = [];
                let pass_train_values = [];
                let fail_train_values = [];
                let pass_test_values = [];
                let fail_test_values = [];
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        features.push(b.key);
                        pass_train_values.push(b.pass_train.value !== null && b.pass_train.value !== undefined ? b.pass_train.value.toFixed(2) : 0);
                        fail_train_values.push(b.fail_train.value !== null && b.fail_train.value !== undefined ? b.fail_train.value.toFixed(2) : 0);
                        pass_test_values.push(b.pass_test.value !== null && b.pass_test.value !== undefined ? b.pass_test.value.toFixed(2) : 0);
                        fail_test_values.push(b.fail_test.value !== null && b.fail_test.value !== undefined ? b.fail_test.value.toFixed(2) : 0);
                    }
                );
                let dataFinal = { feature: features, pass_train: pass_train_values, fail_train: fail_train_values, pass_test: pass_test_values, fail_test: fail_test_values };
                return (
                    <div className='featureTable'>
                        <table className='prettyTable'>
                            <thead>
                                <tr>
                                    <th rowspan="2">Feature</th>
                                    <th colspan="2">Train F1</th>
                                    <th colspan="2">Test F1</th>
                                </tr>
                                <tr>
                                    <th>Pass</th>
                                    <th>Fail</th>
                                    <th>Pass</th>
                                    <th>Fail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataFinal.feature.map((feature, index) => (
                                    <tr key={index} >
                                        <td><b>{feature}</b></td>
                                        <td>{dataFinal.pass_train[index]}</td>
                                        <td>{dataFinal.fail_train[index]}</td>
                                        <td>{dataFinal.pass_test[index]}</td>
                                        <td>{dataFinal.fail_test[index]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            }}
        />
    );
}

const RangeTable = ({ componentId, interactiveIds }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    bool: {
                        must: [
                            {
                                range: {
                                    'length of input features': {
                                        gte: 4
                                    }
                                }
                            }
                        ],
                        must_not: [
                            {
                                terms: {
                                    sampling: ["over", "under"]
                                }
                            }
                        ]
                    }
                },
                aggs: {
                    data: {
                        range: {
                            field: 'length of input features',
                            ranges: [
                                { from: 0, to: 100 },
                                { from: 100, to: 250 },
                                { from: 250, to: 500 },
                                { from: 500 }
                            ]
                        },
                        aggs: {
                            pass_test: {
                                avg: {
                                    field: 'Test F1 Score - PASS LOT'
                                },
                            },
                            fail_test: {
                                avg: {
                                    field: 'Test F1 Score - FAIL LOT'
                                }
                            },
                            pass_train: {
                                avg: {
                                    field: 'Train F1 Score - PASS LOT'
                                },
                            },
                            fail_train: {
                                avg: {
                                    field: 'Train F1 Score - FAIL LOT'
                                }
                            }
                        },
                    },
                },
                size: 0,
            })
            }
            URLParams
            showFilter
            react={interactiveIds}
            render={({ aggregations, ...rest }) => {
                let features = [];
                let pass_train_values = [];
                let fail_train_values = [];
                let pass_test_values = [];
                let fail_test_values = [];
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        features.push(b.key);
                        pass_train_values.push(b.pass_train.value !== null && b.pass_train.value !== undefined ? b.pass_train.value.toFixed(2) : 0);
                        fail_train_values.push(b.fail_train.value !== null && b.fail_train.value !== undefined ? b.fail_train.value.toFixed(2) : 0);
                        pass_test_values.push(b.pass_test.value !== null && b.pass_test.value !== undefined ? b.pass_test.value.toFixed(2) : 0);
                        fail_test_values.push(b.fail_test.value !== null && b.fail_test.value !== undefined ? b.fail_test.value.toFixed(2) : 0);
                    }
                );
                let dataFinal = { feature: features, pass_train: pass_train_values, fail_train: fail_train_values, pass_test: pass_test_values, fail_test: fail_test_values };
                return (
                    <div className='featureTable'>
                        <table className='prettyTable'>
                            <thead>
                                <tr>
                                    <th rowspan="2">Length of Features</th>
                                    <th colspan="2">Train F1</th>
                                    <th colspan="2">Test F1</th>
                                </tr>
                                <tr>
                                    <th>Pass</th>
                                    <th>Fail</th>
                                    <th>Pass</th>
                                    <th>Fail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataFinal.feature.map((feature, index) => (
                                    <tr key={index} >
                                        <td><b>{feature}</b></td>
                                        <td>{dataFinal.pass_train[index]}</td>
                                        <td>{dataFinal.fail_train[index]}</td>
                                        <td>{dataFinal.pass_test[index]}</td>
                                        <td>{dataFinal.fail_test[index]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            }}
        />
    );
}

const SamplingOver3Tables = ({ interactiveIds }) => {
    return (
        <>
            <div className='col-6'>
                <ErrorBoundary>
                    <FeatureTable componentId='FeatureTable' interactiveIds={interactiveIds} />
                </ErrorBoundary>
            </div>
            <div className='col-6'>
                <ErrorBoundary>
                    <RangeTable componentId='RangeTable' interactiveIds={interactiveIds} />
                </ErrorBoundary>
            </div>
        </>)
}


export default SamplingOver3Tables;