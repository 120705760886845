import React from 'react';
import {
  DateRange,
  MultiList,
  ReactiveBase,
  ReactiveComponent,
  SelectedFilters,
  ReactiveChart
} from '@appbaseio/reactivesearch';
import { Tabs, Spinner, Tab } from 'react-bootstrap';
import { useReactiveProps } from '../ReactiveProps';
import theme from './ReactiveBaseTheme';
import dateformat from 'dateformat';
import ErrorBoundary from '../components/ErrorBoundary';
import { ReferenceLine } from 'recharts';
import LineChartComponent from '../components/Charts/LineChartComponentWOLegend';
import DataOverviewDetails from '../components/Groups/DataOverviewDetails';
import getRandomColors from '../components/Groups/ChartOptions/GetRandomColors';
import '../components/Groups/CSS/Home.css'

const interactiveIds = {
  and: [
    'Start_Time',
    'Start_Time_Range',
    'Lot_Number',
    'Carrier',
    'Device',
    'Port'
  ],
};

const colors = getRandomColors(30);

const handleGoBack = () => {
  window.history.back();
};

const getLSTMLinkParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const lotNumbers = urlParams.getAll('Lot_Number');
  const startTimeRange = urlParams.get('Start_Time_Range') ? JSON.parse(urlParams.get('Start_Time_Range')) : null;

  // Create the redirect link with the parameters
  const serverUrl = window.location.origin;
  // Create the redirect link with the parameters
  let redirectUrl = `${serverUrl}/lstm_analysis?interval=1M&agg=max`;

  if (lotNumbers && lotNumbers.length > 0) {
    redirectUrl += `Lot_Number=${lotNumbers.map(number => encodeURIComponent(number)).join(',')}&`;
  }
  if (startTimeRange !== null) {
    redirectUrl += `Start_Time_Range=${encodeURIComponent(JSON.stringify(startTimeRange))}&`;
  }
  // Remove the last '&' from the URL if it exists
  if (redirectUrl.endsWith('&')) {
    redirectUrl = redirectUrl.slice(0, -1);
  }
  // Redirect the user to the new location
  window.location.href = redirectUrl;
};

const getTimeSeriesLinkParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const lotNumbers = urlParams.getAll('Lot_Number');
  const startTimeRange = urlParams.get('Start_Time_Range') ? JSON.parse(urlParams.get('Start_Time_Range')) : null;

  // Create the redirect link with the parameters
  const serverUrl = window.location.origin;
  // Create the redirect link with the parameters
  let redirectUrl = `${serverUrl}/time_series_analysis?`;

  if (lotNumbers && lotNumbers.length > 0) {
    redirectUrl += `Lot_Number=${lotNumbers.map(number => encodeURIComponent(number)).join(',')}&`;
  }
  if (startTimeRange !== null) {
    redirectUrl += `Start_Time_Range=${encodeURIComponent(JSON.stringify(startTimeRange))}&`;
  }
  // Remove the last '&' from the URL if it exists
  if (redirectUrl.endsWith('&')) {
    redirectUrl = redirectUrl.slice(0, -1);
  }
  // Redirect the user to the new location
  window.location.href = redirectUrl;
};

const LineChart = ({ componentId }) => (
  <ReactiveComponent
    componentId={componentId}
    defaultQuery={() => ({
      query: {
        match_all: {},
      },
      aggs: {
        date_bucket: {
          date_histogram: {
            field: 'timestamp',
            calendar_interval: '1h',
            format: 'dd-MM-yyyy',
          },
          aggs: {
            lot_number: {
              terms: {
                field: 'mid',
              },
            },
          },
        },
      },
      size: 0,
    })}
    URLParams
    showFilter
    react={interactiveIds}
  >
    {({ aggregations, isLoading }) => {
      if (isLoading || !aggregations) return null;

      const dataKeys = new Set();
      const data = aggregations['date_bucket'].buckets.map((b) => {
        const bucket = { key: dateformat(b.key, 'dd.mm.yyyy HH:MM') };
        const lines = b.lot_number.buckets;
        lines.forEach((a) => {
          bucket[a.key] = a.doc_count;
          dataKeys.add(a.key);
        });
        return bucket;
      });
      return (
        <LineChartComponent
          data={data}
          dataKeys={Array.from(dataKeys).sort((a, b) => a > b)}
          aspect={3}
        >
          <ReferenceLine x={580} stroke="red" strokeDasharray="3 3" />
        </LineChartComponent>
      );
    }}
  </ReactiveComponent>
);

const ReactiveLineChart = ({ componentId }) => (
  <ReactiveChart
    className="custom-chart"
    style={{ height: '1000%', width: '100%', backgroundColor: '#C5DAEE' }}
    componentId={componentId}
    title="Reactive Chart"
    dataField="timestamp"
    chartType="line"
    URLParams
    showFilter
    react={interactiveIds}
    defaultQuery={() => ({
      query: {
        match_all: {
        },
      },
      aggs: {
        timestampAggs: {
          date_histogram: {
            field: 'timestamp',
            calendar_interval: '1M',
            format: 'dd-MM-yyyy',
          },
          aggs: {
            lotNumbersAggs: {
              terms: {
                field: 'mid',
                size: 100
              },
            },
          },
        },
      },
    })}
    setOption={({ rawData }) => {
      const timestampLotNumberTable = {};
      const lotNumberTimestampTable = {};
      const aggs = rawData ? rawData.aggregations : null;
      if (aggs) {
        aggs.timestampAggs.buckets.forEach((doc) => {
          const timestamp_dt = doc.key_as_string;
          timestampLotNumberTable[timestamp_dt] = {};
          doc.lotNumbersAggs.buckets.forEach((lotNumber) => {
            timestampLotNumberTable[timestamp_dt][lotNumber.key] = 0;
            timestampLotNumberTable[timestamp_dt][lotNumber.key]
              = lotNumber.doc_count;
          });
        });
        Object.keys(timestampLotNumberTable).forEach((timestamp_dt) => {
          Object.keys(timestampLotNumberTable[timestamp_dt]).forEach((lotNumber) => {
            if (!lotNumberTimestampTable[lotNumber]) {
              lotNumberTimestampTable[lotNumber] = {};
            }
            lotNumberTimestampTable[lotNumber][timestamp_dt]
              = timestampLotNumberTable[timestamp_dt][lotNumber];
          });
        });
      }
      return {
        legend: {},
        xAxis: {
          data: Object.keys(timestampLotNumberTable).map(k => k),
        },
        yAxis: {},
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            axis: 'x',
          },
        },
        series: Object.keys(lotNumberTimestampTable).map((lot_number, index) => ({
          data: Object.keys(lotNumberTimestampTable[lot_number])
            .sort()
            .map(timestamp_dt => lotNumberTimestampTable[lot_number][timestamp_dt]),
          type: 'line',
          name: lot_number,
          itemStyle: {
            color: colors[index % colors.length],
          },
        })),
      };
    }}
  />
)

export default function Overview(props) {
  const reactiveProps = useReactiveProps('irel40_raw_kulim');
  if (!reactiveProps) return <div style={{ textAlign: "center" }}>Please Login</div>;

  return (
    <div>
      <hr />

      <ReactiveBase {...reactiveProps} theme={theme}>
        <div className="container-fluid my-2">
          <div className="row g-2">
            <div className="col-3">
              <div className="p-1 bg-white">
                <ErrorBoundary>
                  <h6>Select Event Time (yyyy-mm-dd)</h6>
                  <DateRange
                    componentId="Start_Time_Range"
                    URLParams
                    showFilter
                    dataField="timestamp"
                    filterLabel="Start Time Range"
                  />
                  <hr />
                  <MultiList
                    componentId="Lot_Number"
                    dataField="mid"
                    title="Lot Number"
                    showCount
                    showFilter
                    showSearch={true}
                    placeholder="Search Lot Number..."
                    loader={<Spinner animation="border" size="sm" />}
                    URLParams
                    innerClass={{
                      title: 'fw-bold',
                    }}
                    className="mt-1"
                    filterLabel="Lot Number"
                  />
                  <MultiList
                    componentId="Carrier"
                    dataField="carrier"
                    title="Carrier"
                    showCount
                    showFilter
                    showSearch={false}
                    loader={<Spinner animation="border" size="sm" />}
                    URLParams
                    innerClass={{
                      title: 'fw-bold',
                    }}
                    className="mt-1"
                  />
                  <MultiList
                    componentId="Device"
                    dataField="device"
                    title="Device"
                    showCount
                    showFilter
                    showSearch={false}
                    loader={<Spinner animation="border" size="sm" />}
                    URLParams
                    innerClass={{
                      title: 'fw-bold',
                    }}
                    className="mt-1"
                  />
                  <MultiList
                    componentId="Port"
                    dataField="port"
                    title="Port"
                    showCount
                    showFilter
                    showSearch={false}
                    loader={<Spinner animation="border" size="sm" />}
                    URLParams
                    innerClass={{
                      title: 'fw-bold',
                    }}
                    className="mt-1"
                  />
                </ErrorBoundary>
              </div>
            </div>
            <div className="col-9">
              <div className="p-1 bg-white">
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Check filtered Data:</span>
                    <button
                      style={{
                        padding: '3px 5px', // Reduced padding on top and bottom
                        borderRadius: '5px',
                        backgroundColor: '#07529a',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        marginBottom: '5px',
                        marginRight: '5px'
                      }}
                      onClick={getLSTMLinkParams}
                    >
                      LSTM Autoencoder
                    </button>
                    <button
                      style={{
                        padding: '3px 5px',
                        borderRadius: '5px',
                        backgroundColor: '#07529a',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        marginBottom: '5px',
                        marginRight: '5px'
                      }}
                      onClick={getTimeSeriesLinkParams}
                    >
                      Time Series
                    </button>
                    <button
                      style={{
                        padding: '3px 15px',
                        borderRadius: '5px',
                        backgroundColor: '#38393b',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        marginBottom: '5px',
                        marginRight: '5px'
                      }}
                      onClick={handleGoBack}
                      title="Go Back"
                    >
                      <b>⭯</b>
                    </button>
                  </div>
                </div>
                <SelectedFilters className="mb-2" />
                <div
                  className="border shadow-sm p-1 mb-2"
                  style={{ minWidth: '300px' }}
                >
                  <Tabs defaultActiveKey="charts" className="mb-2">
                    <Tab eventKey="charts" title="Overview">
                      <div className="container">
                        <div className="row">
                          <h5 style={{ textAlign: 'center' }}>Document Count over Time</h5>
                          <LineChart componentId='dataOverviewLineChart' />
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="details" title="Details">
                      <DataOverviewDetails interactiveIds={interactiveIds} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactiveBase>
      <hr />
    </div>
  )
}