import {
    ReactiveChart
} from '@appbaseio/reactivesearch';
import getRandomColors from './ChartOptions/GetRandomColors';

const colors = getRandomColors(30);


const BarChart = ({ componentId, interactiveIds }) => {
    return (
        <div className="col">
            <ReactiveChart
                componentId={componentId}
                dataField="reconstruction_loss"
                chartType="bar"
                URLParams
                useAsFilter={false}
                title="Lot_number"
                react={interactiveIds}
                xAxisName="Reconstruction Loss"
                defaultQuery={() => ({
                    aggs: {
                        recon_loss: {
                            histogram: {
                                field: 'reconstruction_loss',
                                interval: 10,
                            },
                            aggs: {
                                lot_numbers: {
                                    terms: {
                                        field: 'LOT_number',
                                        size: 30, // Number of top LOT_numbers to show
                                    },
                                },
                            },
                        },
                    },
                })}
                setOption={({ rawData }) => {
                    if (!rawData || !rawData.aggregations || !rawData.aggregations.recon_loss) {
                        return {}; // Handle no data scenario
                    }

                    const aggs = rawData.aggregations.recon_loss;
                    const reconLossLotNumberTable = {};
                    const lotNumberReconLossTable = {};

                    aggs.buckets.forEach((reconLossBucket) => {
                        const reconLossValue = reconLossBucket.key;
                        const lotNumbersAggs = reconLossBucket.lot_numbers.buckets;

                        lotNumbersAggs.forEach((lotNumberBucket) => {
                            const lotNumber = lotNumberBucket.key;
                            if (!reconLossLotNumberTable[reconLossValue]) {
                                reconLossLotNumberTable[reconLossValue] = {};
                            }
                            reconLossLotNumberTable[reconLossValue][lotNumber] = lotNumberBucket.doc_count;
                        });
                    });

                    Object.keys(reconLossLotNumberTable).forEach((reconLossValue) => {
                        Object.keys(reconLossLotNumberTable[reconLossValue]).forEach((lotNumber) => {
                            if (!lotNumberReconLossTable[lotNumber]) {
                                lotNumberReconLossTable[lotNumber] = {};
                            }
                            lotNumberReconLossTable[lotNumber][reconLossValue] =
                                reconLossLotNumberTable[reconLossValue][lotNumber];
                        });
                    });

                    const xAxisData = Object.keys(reconLossLotNumberTable);
                    const series = Object.keys(lotNumberReconLossTable).map((lotNumber, index) => ({
                        data: xAxisData.map((reconLossValue) => reconLossLotNumberTable[reconLossValue][lotNumber]),
                        stack: 'x',
                        type: 'bar',
                        name: lotNumber,
                        itemStyle: { color: colors[index] },
                    }));

                    return {
                        legend: {},
                        xAxis: { data: xAxisData },
                        yAxis: {},
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'line',
                                axis: 'x',
                            },
                            formatter: (params) => {
                                const axisValue = params[0]?.axisValue;
                                const tooltipLines = params
                                    .filter((param) => param.value !== undefined) // Filter out lines with value undefined
                                    .map((param) => {
                                        const dot = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:${param.color}"></span>`;
                                        return `<b>${dot}${param.seriesName}:</b> ${param.value}`;
                                    })
                                    .join('<br>');

                                return `<b>Reconstruction Loss ~ </b> ${axisValue}<br>${tooltipLines}`;
                            },
                        },
                        series: series,
                    };
                }}
            />
        </div>
    );
};

const LSTMCharts = ({ interactiveIds }) => {
    return (
        <div
            className="border shadow-sm p-1 mb-2"
            style={{ minWidth: '300px' }}
        >
            <div className="row">
                <div style={{ minHeight: '400px' }}>
                    <h5 style={{ textAlign: 'center' }}>Reconstruction Loss Histogram</h5>
                    <BarChart componentId='recon_hist' interactiveIds={interactiveIds} />
                </div>
            </div>
        </div>
    )
}

export default LSTMCharts;