
import {
    SelectedFilters,
    ReactiveComponent
} from '@appbaseio/reactivesearch';
import { get, map } from 'lodash';
import ErrorBoundary from '../ErrorBoundary';
import ReactApexChart from 'react-apexcharts';
import getBarChartOptions from './ChartOptions/BarChartOptions';
import SamplingOver3Tables from './SamplingOver3Tables';

const MLResultsBarChart = ({ componentId, type, interactiveIds }) => {
    let pass_agg = '';
    let fail_agg = '';
    if (type === 'train') {
        pass_agg = 'Train F1 Score - PASS LOT'
        fail_agg = 'Train F1 Score - FAIL LOT'
    } else {
        pass_agg = 'Test F1 Score - PASS LOT'
        fail_agg = 'Test F1 Score - FAIL LOT'
    }
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    bool: {
                        must: [
                            {
                                range: {
                                    'length of input features': {
                                        gte: 4
                                    }
                                }
                            }
                        ],
                        must_not: [
                            {
                                terms: {
                                    sampling: ["over", "under"]
                                }
                            }
                        ]
                    }
                },
                aggs: {
                    data: {
                        terms: {
                            field: 'training features',
                            size: 1000
                        },
                        aggs: {
                            pass: {
                                max: {
                                    field: pass_agg
                                },
                            },
                            fail: {
                                max: {
                                    field: fail_agg
                                }
                            }
                        },
                    },
                },
                size: 0,
            })
            }
            URLParams
            showFilter
            react={interactiveIds}
            render={({ aggregations, ...rest }) => {
                let datakeys = [];
                let passValues = [];
                let failValues = [];
                const data = map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        datakeys.push(b.key);
                        passValues.push(b.pass.value.toFixed(2));
                        failValues.push(b.fail.value.toFixed(2));
                    }
                );
                let indices = datakeys.map((_, index) => index);

                // Sort passValues in descending order and rearrange the indices array accordingly
                indices.sort((a, b) => passValues[b] - passValues[a]);

                // Rearrange the elements in datakeys, passValues, and failValues based on the sorted indices
                datakeys = indices.map(index => datakeys[index]);
                passValues = indices.map(index => passValues[index]);
                failValues = indices.map(index => failValues[index]);

                const dataSeries = [{ name: 'Pass', data: passValues }, { name: 'Fail', data: failValues }];

                const BarChartOptions = getBarChartOptions(datakeys);
                return (
                    <div className='chart'>
                        <ReactApexChart options={BarChartOptions} series={dataSeries} type="bar" height={450} />
                    </div>
                );
            }}
        />
    );
}

const getLineChartOptions = (dataKeys) => {
    const LineChartOptions = {
        chart: {
            height: 350,
            type: 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
            },
            toolbar: {
                show: false
            }
        },
        colors: ['#1f9c40', '#a80a17'],
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        markers: {
            size: 1
        },
        xaxis: {
            categories: dataKeys,
            title: {
                text: 'Length of Input Feature'
            }
        },
        yaxis: {
            tickAmount: 11,
            min: 0,
            max: 1,
            labels: {
                formatter: function (value) {
                    return parseFloat(value).toFixed(1); // Format the label to display one decimal place
                }
            },
            title: {
                text: 'F1 Score'
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center'
        }
    }
    return LineChartOptions;
}

const MLResultsLineChart = ({ componentId, type, interactiveIds }) => {
    let pass_agg = '';
    let fail_agg = '';
    if (type === 'train') {
        pass_agg = 'Train F1 Score - PASS LOT'
        fail_agg = 'Train F1 Score - FAIL LOT'
    } else {
        pass_agg = 'Test F1 Score - PASS LOT'
        fail_agg = 'Test F1 Score - FAIL LOT'
    }
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    bool: {
                        must: [
                            {
                                range: {
                                    'length of input features': {
                                        gte: 4
                                    }
                                }
                            }
                        ],
                        must_not: [
                            {
                                terms: {
                                    sampling: ["over", "under"]
                                }
                            }
                        ]
                    }
                },
                aggs: {
                    data: {
                        terms: {
                            field: 'length of input features',
                            size: 5000
                        },
                        aggs: {
                            pass: {
                                avg: {
                                    field: pass_agg
                                },
                            },
                            fail: {
                                avg: {
                                    field: fail_agg
                                }
                            }
                        },
                    },
                },
                size: 0,
            })
            }
            URLParams
            showFilter
            react={interactiveIds}
            render={({ aggregations, ...rest }) => {
                let datakeys = [];
                let passValues = [];
                let failValues = [];
                const data = map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        datakeys.push(b.key);
                        passValues.push(b.pass.value.toFixed(2));
                        failValues.push(b.fail.value.toFixed(2));
                    }
                );
                const dataSeries = [{ name: 'Pass', data: passValues }, { name: 'Fail', data: failValues }];

                const BarChartOptions = getLineChartOptions(datakeys);
                return (
                    <div className='chart'>
                        <ReactApexChart options={BarChartOptions} series={dataSeries} type="line" height={450} />
                    </div>
                );
            }}
        />
    );
}

const MLResultsSamplingOver3 = ({ interactiveIds }) => {
    return (
        <div className="container-fluid my-2">
            <div className="row g-2">
                <div className="col-12">
                    <div className="p-2 bg-white">
                        <div className="row">
                            <div className='col-12'>
                                <h5>Length of input feature above 3</h5>
                            </div>
                        </div>
                        <div
                            className="border shadow-sm p-2 mb-2"
                            style={{ minWidth: '300px' }}
                        >
                            <div className="row">
                                <SamplingOver3Tables interactiveIds={interactiveIds} />
                            </div>
                            <hr />
                            <div className="row">
                                <div className='col-6'>
                                    <h5 style={{ textAlign: 'center' }}>Best Train F1 Scores per Training Feature</h5>
                                    <ErrorBoundary>
                                        <MLResultsBarChart componentId="homeBarchartTrainO3" type="train" interactiveIds={interactiveIds} />
                                    </ErrorBoundary>
                                </div>
                                <div className='col-6'>
                                    <h5 style={{ textAlign: 'center' }}>F1 Train Scores after SHAP analysis</h5>
                                    <ErrorBoundary>
                                        <MLResultsLineChart componentId="homeLinechartTrainO3" type="train" interactiveIds={interactiveIds} />
                                    </ErrorBoundary>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className='col-6'>
                                    <h5 style={{ textAlign: 'center' }}>Best Test F1 Scores per Training Feature</h5>
                                    <ErrorBoundary>
                                        <MLResultsBarChart componentId="homeBarchartTestO3" type="test" interactiveIds={interactiveIds} />
                                    </ErrorBoundary>
                                </div>
                                <div className='col-6'>
                                    <h5 style={{ textAlign: 'center' }}>F1 Test Scores after SHAP analysis</h5>
                                    <ErrorBoundary>
                                        <MLResultsLineChart componentId="homeLinechartTestO3" type="test" interactiveIds={interactiveIds} />
                                    </ErrorBoundary>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MLResultsSamplingOver3;