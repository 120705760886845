const getTreeMapOptions = (lot_type, dataCount) => {
    let colors = [];
    let title = '';
    if (lot_type === 'pass') {
        colors = ["#004C00"];
        title = 'Duration per Lot - Pass: ' + dataCount + ' Lots';
    } else {
        colors = ['#FF0000'];
        title = 'Duration per Lot - Fail: ' + dataCount + ' Lots';
    }
    const treeMapOptions = {
        legend: {
            show: false
        },
        title: {
            text: title
        },
        chart: {
            height: 550,
            type: 'treemap'
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '12px',
            },
            formatter: function (text, op) {
                return [text, op.value]
            },
            offsetY: -4
        },
        colors: colors,
        plotOptions: {
            treemap: {
                distributed: true
            }
        }
    }
    return treeMapOptions;
};
export default getTreeMapOptions;