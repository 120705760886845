import { useReactiveProps } from "../../ReactiveProps";
import {
    ReactiveComponent,
    ReactiveBase,
    SingleDropdownList,
    SelectedFilters,
    ReactiveList
} from "@appbaseio/reactivesearch";
import ReactApexChart from "react-apexcharts";
import { map, get } from "lodash";
import '../Groups/CSS/LSTMPredictions.css'



const getColumnChartOptions = (dataKeys, threshold) => {
    const BarChartOptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        colors: ['#1f9c40', '#a80a17'],
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: false,
            }
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: dataKeys,
            position: 'bottom'
        },
        tooltip: {
            enabled: true,
            shared: false,
            trigger: 'axis',
            x: {
                formatter: function (value) {
                    return '95% Quantile: ' + value;
                },
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center',
        },
        annotations: {
            xaxis: [
                {
                    x: '' + threshold,
                    strokeDashArray: 5,
                    borderColor: '#a80a17',
                    label: {
                        borderColor: '#a80a17',
                        style: {
                            fontSize: '12px',
                            color: '#fff',
                            background: '#a80a17',
                        },
                        offsetY: -5,
                        offsetX: 20,
                        text: 'Threshold: ' + threshold,
                    },
                },
            ],
        },
    }
    return BarChartOptions;
};

const LossComparisonTable = ({ componentId }) => {
    return (
        <div className="table-outer-container">
            <ReactiveList
                componentId="TableComponent"
                dataField="timestamp"
                size={10}
            >
                {({ data }) => (
                    <div className="table-container">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Configuration Hash</th>
                                    <th>Threshold</th>
                                    <th>Pass Lots (Training)</th>
                                    <th>Length of Pass Lots</th>
                                    <th>Fail Lots (Testing)</th>
                                    <th>Length of Fail Lots</th>
                                    <th>Count</th>
                                    <th>% q.95(Fail) - q.95(Train)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(item => (
                                    <tr key={item._id}>
                                        <td>{item['config hash']}</td>
                                        <td>{item['threshold']}</td>
                                        <td>{item['train lots']}</td>
                                        <td>{item['number of train lots']}</td>
                                        <td>{item['fail lots']}</td>
                                        <td>{item['number of fail lots']}</td>
                                        <td>{item['count']}</td>
                                        <td>{item['95%']}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </ReactiveList>
        </div>
    );
}

const ColumnChart = ({ componentId }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: {
                    data: {
                        histogram: {
                            field: '95%',
                            interval: 0.05
                        },
                        aggs: {
                            lot_type: {
                                terms: {
                                    field: 'lot_status',
                                    order: {
                                        _key: 'desc'
                                    }
                                }, aggs: {
                                    no_samples: {
                                        sum: {
                                            field: 'count'
                                        },
                                    },
                                },
                            },
                            threshold: {
                                avg: {
                                    field: 'threshold'
                                },
                            },
                        },
                    },
                },
                size: 0,
            })
            }

            showFilter
            react={{ and: ['hash_id_list'] }}
            //react={interactiveIds}
            render={({ aggregations, ...rest }) => {
                let datakeys = [];
                let passValues = [];
                let failValues = [];
                let thresholds = [];
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        if (b.threshold) {
                            thresholds.push(b.threshold.value);
                        };
                        let xAxisKey = b.key;
                        datakeys.push(xAxisKey.toFixed(2));
                        if (b.lot_type.buckets.length === 2) {
                            passValues.push(b.lot_type.buckets[0].doc_count);
                            failValues.push(b.lot_type.buckets[1].doc_count);
                        } else if (b.lot_type.buckets.length > 0) {
                            if (b.lot_type.buckets[0].key === 'train_pass') {
                                passValues.push(b.lot_type.buckets[0].doc_count);
                                failValues.push(0);
                            } else {
                                passValues.push(0);
                                failValues.push(b.lot_type.buckets[0].doc_count);
                            }
                        }

                    }
                );

                //Average of threasholds:
                const sumThresholds = thresholds.reduce((total, currentValue) => total + currentValue, 0);
                const averageThreshold = (sumThresholds / thresholds.length);
                const roundedAverage = (Math.round(averageThreshold / 0.05) * 0.05).toFixed(2);

                const dataSeries = [{ name: 'Pass', data: passValues }, { name: 'Fail', data: failValues }];
                const barChartOptions = getColumnChartOptions(datakeys, roundedAverage);
                return (
                    <div className='chart'>
                        <ReactApexChart options={barChartOptions} series={dataSeries} type="bar" height={450} />
                        <hr />
                    </div>
                );
            }}
        />
    );
}

const LSTMPredictions = () => {
    const reactiveProps = useReactiveProps('irel40_raw_kulim_autoencoder_predictions');
    if (!reactiveProps) return <div />;
    return (
        <div>
            <ReactiveBase {...reactiveProps}>
                <SelectedFilters className="mb-2" />
                <p style={{ textAlign: 'right', fontSize: 'small', fontStyle: 'italic', color: '#888' }}>* Please note that filters do not work on this page.</p>
                <div
                    className="border shadow-sm p-1 mb-2"
                    style={{ minWidth: '300px' }}
                >
                    <div className="row">
                        <SingleDropdownList
                            componentId="hash_id_list"
                            dataField="config hash"
                            sortBy="count"
                            showCount={true}
                            showSearch={true}
                            placeholder="Select Config Hash"
                            showFilter={true}
                            filterLabel="Config Hash"
                            URLParams={true}
                            loader="Loading ..." />
                    </div>
                    <div className="row">
                        <h5 style={{ textAlign: 'center' }}>95% Quantile Loss Histogram</h5>
                        <ColumnChart componentId='column_chart' />
                    </div>
                    <div className="row">
                        <h5 style={{ textAlign: 'center' }}>Loss Comparison</h5>
                        <LossComparisonTable componentId='column_chart' />
                    </div>
                </div>
            </ReactiveBase>
        </div>
    )
}

export default LSTMPredictions;