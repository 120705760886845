/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react';

export const useAsyncError = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, setError] = React.useState();
  return React.useCallback(
    (e) => {
      setError(() => {
        throw e;
      });
    },
    [setError],
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  /* static getDerivedStateFromError(error) {
    return { error };
  } */

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    console.error('did catch:', error, errorInfo);
  }

  render() {
    const { props, state } = this;
    if (state.errorInfo) {
      return (
        <div className="alert alert-danger">
          <div className="fw-bold">Something went wrong.</div>
          {process.env.NODE_ENV === 'development' && (
            <details className="text-prewrap">{state.error?.toString()}</details>
          )}
        </div>
      );
    }
    // eslint-disable-next-line react/prop-types
    return props.children;
  }
}

export default ErrorBoundary;
