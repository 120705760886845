const getRandomColors = (size) => {
  const colors = ['#0e7329','#db6714','#231cb8','#c7141d','#31f505','#515252','#CC6677','#882255','#AA4499'];

  for (let i = 0; i < size; i++) {
    const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
    colors.push(color);
  }

  return colors;
};

export default getRandomColors;