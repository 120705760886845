/* eslint-disable react/prop-types */
import React from 'react';
import {
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { includes, without } from 'lodash';
import { range9 as colors } from './colors';

function LineChartComponent({ data, dataKeys, yaxis, aspect = 2, children }) {
  const [disabled, setDisabled] = React.useState([]);
  const xLabel =
    dataKeys || without(data?.length ? Object.keys(data[0]) : [], 'key');

  return (
    <ResponsiveContainer width="100%" height="100%" aspect={aspect}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 20,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="key" interval={'preserveStartEnd'} />
        <YAxis {...(yaxis || {})} />
        {/*<Legend
          payload={xLabel.map((l, i) => ({
            dataKey: l,
            color: colors[i % colors.length],
          }))}
          content={renderCustomizedLegend}
        />*/}
        <Tooltip isAnimationActive={false} />
        {xLabel.map((l, i) => {
          return (
            !includes(disabled, l) && (
              <Line
                key={l}
                dataKey={l}
                stroke={colors[i % colors.length]}
                dot={false}
                isAnimationActive={false}
                strokeWidth={2}
              />
            )
          );
        })}
        <Brush height={16} />
        {children}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default React.memo(LineChartComponent);

export function elasticToLineChartData(aggs) {
  if (!aggs) return [];
  const map = new Map();
  Object.keys(aggs).forEach((k) => {
    try {
      convertLineChartData(aggs[k].buckets, k, map);
    } catch (error) {
      console.error(error);
    }
  });
  return map.values();
}

function convertLineChartData(buckets, field, map) {
  buckets.forEach((b) => {
    if (map.has(b.key)) {
      map.set(b.key, {
        ...map.get(b.key),
        ...{ key: b.key, [field]: b.doc_count },
      });
    } else {
      map.set(b.key, {
        key: b.key,
        [field]: b.doc_count,
      });
    }
  });
}
