import React from 'react';
import {
  ReactiveBase
} from '@appbaseio/reactivesearch';
import { Tabs, Tab } from 'react-bootstrap';
import { useReactiveProps } from '../ReactiveProps';
import theme from './ReactiveBaseTheme';
import TimeSeriesPrediction from '../components/Groups/TimeSeriesPredictions';
import TimeSeriesOverview from '../components/Groups/TimeSeriesOverview';

const getLSTMLinkParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const lotNumbers = urlParams.getAll('Lot_Number');
  const startTimeRange = urlParams.get('Start_Time_Range') ? JSON.parse(urlParams.get('Start_Time_Range')) : null;
  const lotType = urlParams.getAll('Lot_Type');

  // Create the redirect link with the parameters
  const serverUrl = window.location.origin;
  // Create the redirect link with the parameters
  let redirectUrl = `${serverUrl}/lstm_analysis?interval=1M&agg=max`;

  if (lotNumbers && lotNumbers.length > 0) {
    redirectUrl += `Lot_Number=${lotNumbers.map(number => encodeURIComponent(number)).join(',')}&`;
  }
  if (startTimeRange !== null) {
    redirectUrl += `Start_Time_Range=${encodeURIComponent(JSON.stringify(startTimeRange))}&`;
  }
  if (lotType && lotType.length > 0) {
    redirectUrl += `Lot_Type=${lotType.map(type => encodeURIComponent(type)).join(',')}&`;
  }
  // Remove the last '&' from the URL if it exists
  if (redirectUrl.endsWith('&')) {
    redirectUrl = redirectUrl.slice(0, -1);
  }
  // Redirect the user to the new location
  window.location.href = redirectUrl;
};

const getOverviewLinkParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const lotNumbers = urlParams.getAll('Lot_Number');
  const startTimeRange = urlParams.get('Start_Time_Range') ? JSON.parse(urlParams.get('Start_Time_Range')) : null;

  // Create the redirect link with the parameters
  const serverUrl = window.location.origin;
  // Create the redirect link with the parameters
  let redirectUrl = `${serverUrl}/?`;

  if (lotNumbers && lotNumbers.length > 0) {
    redirectUrl += `Lot_Number=${lotNumbers.map(number => encodeURIComponent(number)).join(',')}&`;
  }
  if (startTimeRange !== null) {
    redirectUrl += `Start_Time_Range=${encodeURIComponent(JSON.stringify(startTimeRange))}&`;
  }
  // Remove the last '&' from the URL if it exists
  if (redirectUrl.endsWith('&')) {
    redirectUrl = redirectUrl.slice(0, -1);
  }
  // Redirect the user to the new location
  window.location.href = redirectUrl;
};

const getMLResultsLinkParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const features = urlParams.getAll('Feature');

  // Create the redirect link with the parameters
  const serverUrl = window.location.origin;
  // Create the redirect link with the parameters
  let redirectUrl = `${serverUrl}/ml_results?`;

  if (features && features.length > 0) {
    redirectUrl += `Feature=${features.map(number => encodeURIComponent(number)).join(',')}&`;
  }
  // Redirect the user to the new location
  window.location.href = redirectUrl;
};

const handleGoBack = () => {
  window.history.back();
};

export default function TimeSeries(props) {
  const reactiveProps = useReactiveProps('irel40_raw_kulim_timeanalysis2');
  if (!reactiveProps) return <div style={{ textAlign: "center" }}>Please Login</div>;

  return (
    <div>
      <hr />
      <ReactiveBase {...reactiveProps} theme={theme}>
        <div className="container-fluid my-2">
          <div className="row g-2">
            <div className="col-12">
              <div className="p-1 bg-white">
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Check filtered Data:</span>
                    <button
                      style={{
                        padding: '3px 5px', // Reduced padding on top and bottom
                        borderRadius: '5px',
                        backgroundColor: '#07529a',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        marginBottom: '5px',
                        marginRight: '5px'
                      }}
                      onClick={getLSTMLinkParams}
                    >
                      LSTM Autoencoder
                    </button>
                    <button
                      style={{
                        padding: '3px 5px',
                        borderRadius: '5px',
                        backgroundColor: '#07529a',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        marginBottom: '5px',
                        marginRight: '5px'
                      }}
                      onClick={getOverviewLinkParams}
                    >
                      Raw Data
                    </button>
                    <button
                      style={{
                        padding: '3px 5px',
                        borderRadius: '5px',
                        backgroundColor: '#07529a',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        marginBottom: '5px',
                        marginRight: '5px'
                      }}
                      onClick={getMLResultsLinkParams}
                    >
                      ML Results
                    </button>
                    <button
                      style={{
                        padding: '3px 15px',
                        borderRadius: '5px',
                        backgroundColor: '#38393b',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        marginBottom: '5px',
                        marginRight: '5px'
                      }}
                      onClick={handleGoBack}
                      title="Go Back"
                    >
                      <b>⭯</b>
                    </button>
                  </div>
                </div>
                <div
                  className="border shadow-sm p-1 mb-2"
                  style={{ minWidth: '300px' }}
                >
                  <Tabs defaultActiveKey="charts" className="mb-2">
                    <Tab eventKey="charts" title="Overview">
                      <TimeSeriesOverview />
                    </Tab>
                    <Tab eventKey="predictions" title="Predictions">
                      <div>
                        <TimeSeriesPrediction componentId='TimeSeriesPrediction' />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactiveBase>
      <hr />
    </div>
  )
}