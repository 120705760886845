import {
    ReactiveList,
    ResultCard
} from '@appbaseio/reactivesearch';

const { ResultCardsWrapper } = ReactiveList;

const LSTMDetailsCard = ({ interactiveIds }) => {
    return (
        <div>
            <ReactiveList
                react={interactiveIds}
                componentId="SearchResult"
                dataField="LOT_number"
                size={100}
            >
                {({ data }) => (
                    <ResultCardsWrapper>
                        {
                            data.map(item => (
                                <ResultCard key={item._id} style={{ height: '120px', backgroundColor: '#C5DAEE' }}>

                                    <ResultCard.Title dangerouslySetInnerHTML={{
                                        __html: `Lot Number: <strong>${item.LOT_number}</strong>`
                                    }}
                                    />
                                    <ResultCard.Description>
                                        <div>
                                            <div>
                                                <b>Lot Type:</b> {item.LOT_type}
                                            </div>
                                            <div>
                                                <b>Reconstruction Loss:</b> {item.reconstruction_loss}
                                            </div>
                                            <div>
                                                <b>Start Time:</b> {new Date(item.timestamp).toLocaleDateString('en-GB')}{' '}
                                                {new Date(item.timestamp).toLocaleTimeString('en-GB', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: false
                                                })}
                                            </div>
                                        </div>
                                    </ResultCard.Description>
                                </ResultCard>
                            ))
                        }
                    </ResultCardsWrapper>)}
            </ReactiveList>
        </div>
    )
}

export default LSTMDetailsCard;